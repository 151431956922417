import Repository from '@/libs/axios'

const resource = '/init'
const businessresource = '/register/business-account'

export default {
  sendGoogleAuthenticatedUser(payload) {
    return Repository.post(`${resource}/register`, payload)
  },
  sendUserBusinessDetails(payload) {
    return Repository.post(`${businessresource}`, payload)
  },
}
