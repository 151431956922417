import Repository from '@/libs/axios'

// const resource = '/merchant'
// const changePasswordresource = '/admin/staff/change-password'
const otpverify = '/verify'

export default {
  otp(payload) {
    return Repository.post(`${otpverify}/admin-email`, payload)
  },
  domainValidation(payload) {
    return Repository.post(`${otpverify}/business-domain`, payload)
  },
  resendOtp(payload) {
    return Repository.post(`${otpverify}/resend-otp`, payload)
  },
}
