import authRepository from './authRepository'
import googleAccountRepository from './googleAccountRepository'
import loginRepository from './loginRepository'
import registerRepository from './registerRepository'

const repositories = {
  auth: authRepository,
  googleaccount: googleAccountRepository,
  login: loginRepository,
  register: registerRepository,
}

// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: name => repositories[name],
}
