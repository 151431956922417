import Cookies from 'js-cookie'
import axios from '@/libs/axios'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import router from '@/router/index'

const AuthRepository = RepositoryFactory.get('auth')
const OrderRepository = RepositoryFactory.get('order')

export default {
  state: {
    current_user_data: null,
    current_user_permission: null,
    order_status_list: null,
  },
  mutations: {
    CLEAR_CURRENT_USER: state => {
      state.current_user_data = undefined
      state.current_user_permission = undefined
      Cookies.remove('current_user_token')
    },
    SET_CURRENT_USER_PERMISSION: (state, value) => {
      state.current_user_permission = value
      Cookies.set('current_user_permission', value)
    },

    SET_CURRENT_USER: (state, value) => {
      state.current_user_data = value.user
      Cookies.set('current_user_token', value.token)
      axios.defaults.headers.common.Authorization = `Bearer ${value.token}`
    },

    SET_ORDER_STATUS_LIST: (state, value) => {
      state.order_status_list = value
      Cookies.set('order_status_list', value)
    },
  },
  getters: {
    isLoggedIn: state => state.current_user_data !== null,
    currentUser: state => state.current_user_data,
    permissions: state => state.current_user_permission,
    statusList: state => state.order_status_list,
  },
  actions: {
    setCurrentUser: ({ commit }, payload) => { commit('SET_CURRENT_USER', payload) },

    // eslint-disable-next-line no-unused-vars
    async afterLogin({ commit }, fromLogin) {
      if (!fromLogin) router.replace('/all-orders')
    },
    async autoLogin({ commit }) {
      try {
        // eslint-disable-next-line camelcase
        const cacheUserToken = Cookies.get('current_user_token')

        if (!this.getters.isLoggedIn && cacheUserToken) {
          // axios.defaults.headers.common.Authorization = `Bearer ${cacheUserToken}`
          const cacheUserData = (await AuthRepository.getCurrentUser()).data
          commit('SET_CURRENT_USER', {
            user: cacheUserData.data,
            token: cacheUserToken,
          })

          const permissions = await AuthRepository.getPermissions()
          commit('SET_CURRENT_USER_PERMISSION', permissions.data.data.map(x => x.name))

          const statuses = (await OrderRepository.getOrderStatusList()).data
          commit('SET_ORDER_STATUS_LIST', statuses.data)

          await this.dispatch('afterLogin', true)
        }
      } catch (e) {
        await this.dispatch('logout')
      }
    },

    async merchantLogin({ commit }, payload) {
      const loginData = (await AuthRepository.login(payload)).data
      commit('SET_CURRENT_USER', loginData)

      const permissions = await AuthRepository.getPermissions()
      commit('SET_CURRENT_USER_PERMISSION', permissions.data.data.map(x => x.name))

      const statuses = (await OrderRepository.getOrderStatusList()).data
      commit('SET_ORDER_STATUS_LIST', statuses.data)

      await this.dispatch('afterLogin')
    },

    async logout({ commit }) {
      // Cookies.remove('current_user_token')
      commit('CLEAR_CURRENT_USER')
      // await StaffAuthRepository.staffLogout()
      window.location = '/login'
    },

  },
}
