import Repository from '@/libs/axios'

const resource = '/staff-admin'
const businessresource = '/register/business-account'

export default {
  sendUserLoginDetails(payload) {
    return Repository.post(`${resource}/login`, payload)
  },
  sendUserBusinessDetails(payload) {
    return Repository.post(`${businessresource}`, payload)
  },
}
