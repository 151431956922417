const psl = require('psl')

let host = ''
// eslint-disable-next-line no-unused-vars
let endPoint = ''
if (`${process.env.VUE_APP_IS_TENANT}` === '1') {
  const url = window.location.host
  if (url.indexOf('.curfox.com') !== -1) {
    // eslint-disable-next-line prefer-destructuring
    host = url.split('.')[0]
  } else {
    const parsed = psl.parse(url)
    // eslint-disable-next-line
    host = parsed.sld
  }
  endPoint = process.env.VUE_APP_API_URL.replace('*', host)
} else {
  // eslint-disable-next-line no-const-assign
  host = 'mng'
  endPoint = process.env.VUE_APP_API_URL.replace('*', host)
}
// eslint-disable-next-line import/prefer-default-export
export const apiUrl = endPoint
export const bucketUrl = process.env.VUE_APP_BUCKET_URL
export const tenant = host
