import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@/views/Dashboard.vue'),
    //   meta: {
    //     pageTitle: 'Dashboard',
    //     breadcrumb: [
    //       {
    //         text: 'Dashboard',
    //         active: true,
    //       },
    //     ],
    //     help: 'https://curfox.com/docs/dashboard/',
    //   },
    // },
    {
      path: '/register',
      name: 'Register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
        noAuth: true,
        noCache: true,
      },
    },
    {
      path: '/buisness-details',
      name: 'Buisness Details',
      component: () => import('@/views/Buisness.vue'),
      meta: {
        layout: 'full',
        noAuth: true,
      },
    },
    {
      path: '/verify',
      name: 'Verify',
      component: () => import('@/views/Verify.vue'),
      meta: {
        layout: 'full',
        noAuth: true,
        noCache: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        noAuth: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/forgot-password/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/reset-password/:email/:token',
      name: 'reset-password',
      component: () => import('@/views/forgot-password/ResetPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
