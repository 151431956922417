import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations'
import VueSweetalert2 from 'vue-sweetalert2'
// import LetItSnow from 'vue-let-it-snow'
import moment from 'moment'
import authController from '@/router/auth_controller'
import mdiVue from 'mdi-vue'
import * as mdijs from '@mdi/js'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vee-validation'

Vue.mixin({
  methods: {
    // eslint-disable-next-line no-underscore-dangle
    __dateTimeFormatter(date) {
      if (date) {
        return moment.utc(date).local().format('DD/MM/YYYY HH:mm')
      }
      return ''
    },
    // eslint-disable-next-line no-underscore-dangle
    __numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return '0'
    },
    // eslint-disable-next-line no-underscore-dangle
    __focusInputField(name) {
      const formElements = this.$refs.formbuilder.$children[0].$children
      formElements.forEach(formElement => {
        if (formElement.name === name) {
          formElement.$children[0].$children[0].$el.focus()
        }
      })
    },
    // eslint-disable-next-line no-underscore-dangle
    __refineDateQuery(filterQuery, searchKeys) {
      searchKeys.forEach(searchKey => {
        if (filterQuery.includes(searchKey)) {
          const startIndex = filterQuery.indexOf(searchKey)
          let singleDateSelected = true
          try {
            if (filterQuery.substring(startIndex, startIndex + searchKey.length + 17).includes('to')) {
              singleDateSelected = false
            }
          } catch (e) {
            singleDateSelected = true
          }
          if (singleDateSelected) {
            const dateQuery = filterQuery.substring(startIndex, startIndex + searchKey.length + 11)
            // eslint-disable-next-line no-param-reassign
            filterQuery = filterQuery.replaceAll(dateQuery, '')
            const date = dateQuery.split('=')[1]
            // eslint-disable-next-line no-param-reassign
            filterQuery += `&${searchKey}=${date}%20to%20${date}`
          }
        }
      })
      return filterQuery
    },
  },
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(TreeViewPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Sweet Alert
Vue.use(VueSweetalert2)

Vue.use(mdiVue, {
  icons: mdijs,
})
// Vue.use(LetItSnow)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
authController.init(router, store)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
