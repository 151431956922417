export default {
  init(router, store) {
    router.beforeEach(async (to, _, next) => {
      await store.dispatch('autoLogin')

      const { isLoggedIn } = store.getters

      if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        next({
          path: '/',
          replace: true,
        })
      }

      if (!to.meta.noAuth && !isLoggedIn) {
        next({
          path: '/login',
          replace: true,
        })
      }
      return next()
    })
  },
}
